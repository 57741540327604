import { FC, useRef, useState } from 'react';
import './styles.css';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Grid,
  useTheme,
  CardMedia,
} from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {
  LocationOnOutlined,
  CalendarMonthOutlined,
  EventAvailableOutlined,
  LocalActivityOutlined,
} from '@mui/icons-material';
import { TBenefitModal } from './types.d';
import { capitalizeTheFirstLetter } from '../../../../common/utils/functions';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { TransitionsModal } from '../../../../common/components/modal';

export const DetailsCouponsV: FC<TBenefitModal> = ({
  detailsCouponsModal,
  redeemBenefit,
  loadingCreate,
  isAuthenticated,
  disableBenefit,
  showQrCode,
  qrOpen,
  qrCode,
}) => {
  const theme = useTheme();
  const styles = {
    content: {
      borderRadius: '10px',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '@media (max-width: 700px)': {
        borderRadius: '0px',
      },
    },
    contentImg: {
      width: '100%',
      height: '50%',
      position: 'relative',
      borderRadius: '5px',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.7))',
        pointerEvents: 'none',
      },
      '@media (max-width: 600px)': {
        height: '229px',
      },
    },
    boxContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '20px',
      alignItems: 'center',
    },
    actionsBox: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      justifyContent: 'end',
      gap: '10px',
      '@media (max-width: 600px)': {
        marginTop: '10px',
        justifyContent: 'center',
      },
    },
    bottom: {
      width: '277px',
      height: '41px',
      fontSize: '16px',
      textTransform: 'initial',
      color: 'white',
      borderRadius: '10px',
      background: theme.palette.gradientBackground.main,
    },
    circularProgress: {
      color: 'white',
    },
    boxContentScrollable: {
      height: '100%',
      overflow: 'auto',
      width: '100%',
    },
    contentDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media (max-width: 500px)': { flexDirection: 'column', alignItems: 'flex-start' },
    },
    Boxdetails: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginBottom: '10px',
    },
    boxQrContent: {
      padding: '10px',
    },
    titleModalQr: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontSize: '30px',
      fontWeight: 'bold',
      marginLeft: '25px',
    },
    qrContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footerText: {
      color: theme.palette.secondary.contrastText,
      textAlign: 'center',
      fontSize: '20px',
    },
    cardMedia: {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
      overflow: 'hidden',
      margin: 'auto',
    },
  };

  const [open] = useState<boolean>(false);

  const ref: any = useRef(null);

  const handleWheel = (e: any) => {
    if (!ref.current) return;
    // Esto desplaza el contenedor basado en el movimiento vertical del ratón
    ref.current.scrollLeft += e.deltaY;
    e.preventDefault(); // Evita el desplazamiento vertical predeterminado al usar la rueda
  };

  return (
    <Box sx={styles.content}>
      <TransitionsModal
        handleCloseModal={showQrCode}
        state={qrOpen}
        minWidth="430px"
        minHeight="420px"
        width="10%">
        <Box sx={styles.boxQrContent}>
          <Typography fontSize="15px" variant="body2" sx={styles.titleModalQr}>
            {detailsCouponsModal?.name && capitalizeTheFirstLetter(detailsCouponsModal.name)}
          </Typography>
          <Box sx={styles.qrContent}>
            <img
              src={qrCode}
              alt="qr"
              style={{
                width: '280px',
                height: '280px',
              }}
            />
          </Box>
          <Typography sx={styles.footerText}>Reclamar tu beneficio</Typography>
        </Box>
      </TransitionsModal>
      <Box className="hide-scrollbar" sx={styles.boxContentScrollable}>
        <Box sx={styles.contentImg}>
          <CardMedia
            sx={styles.cardMedia}
            component="img"
            image={`${detailsCouponsModal?.path}` || `${ImageNotSupportedIcon}`}
          />
        </Box>
        <Box sx={styles.boxContent}>
          <Box sx={{ marginBottom: open ? '10px' : '20px' }}>
            <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <Typography
                fontSize="20px"
                variant="body2"
                component="h1"
                sx={{ color: '#484848', fontWeight: 'bold' }}>
                {detailsCouponsModal?.name && capitalizeTheFirstLetter(detailsCouponsModal.name)}
              </Typography>
            </Box>
            <Typography fontSize="16px" variant="body2" color="textSecondary" component="p">
              {detailsCouponsModal?.trade}
            </Typography>
          </Box>

          <Box
            className="hide-scrollbar"
            onWheel={handleWheel}
            ref={ref}
            sx={{
              width: '100%',
              display: 'flex',
              gap: '10px',
              overflowX: 'auto',
              marginBottom:
                detailsCouponsModal?.thirdParty && detailsCouponsModal.thirdParty.length <= 1
                  ? '0px'
                  : '20px',
            }}>
            {(detailsCouponsModal?.thirdParty || []).map((x: any) => (
              <Box
                sx={{
                  width: '189px',
                  minWidth: '189px',
                  height: '64px',
                  borderRadius: '10px',
                  border: 'solid 1px grey',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#0eb4e21d',
                  },
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    fontSize: '5px',
                    alignItems: 'center',
                    gap: '5px',
                  }}>
                  <LocationOnOutlined
                    color="action"
                    sx={{ color: theme.palette.primary.light, fontSize: '25px' }}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    sx={{
                      fontSize: '14px',
                      color: theme.palette.secondary.contrastText,
                      marginRight: '10px',
                    }}>
                    {x.address}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  sx={{ fontSize: '14px' }}>
                  {x.city}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box sx={styles.contentDetails}>
            <Box>
              <Box sx={styles.Boxdetails}>
                <CalendarMonthOutlined color="action" sx={{ color: theme.palette.primary.light }} />
                <Typography fontSize="14px" variant="body2" color="textSecondary" component="p">
                  {detailsCouponsModal?.endDate}
                </Typography>
              </Box>
              <Box sx={styles.Boxdetails}>
                <EventAvailableOutlined
                  color="action"
                  sx={{ color: theme.palette.primary.light }}
                />
                <Typography fontSize="14px" variant="body2" color="textSecondary" component="p">
                  {detailsCouponsModal?.usagePerson}
                </Typography>
              </Box>
              <Box sx={styles.Boxdetails}>
                <LocalActivityOutlined color="action" sx={{ color: theme.palette.primary.light }} />
                <Typography fontSize="14px" variant="body2" color="textSecondary" component="p">
                  {detailsCouponsModal?.useLimit}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography fontSize="16px" variant="body2" color="textSecondary" component="p">
            {detailsCouponsModal?.description &&
              capitalizeTheFirstLetter(detailsCouponsModal.description)}
          </Typography>
        </Box>
      </Box>
      <Grid container sx={styles.contentGrid}>
        {/* aqui estan los botones :) */}
        <Grid item xs={12} sm={isAuthenticated ? 10 : 8} lg={12}>
          <Box sx={{ ...styles.actionsBox }}>
            {isAuthenticated && (disableBenefit || detailsCouponsModal?.isClaimed) && (
              <Button
                sx={{ ...styles.bottom }}
                disabled={loadingCreate}
                title="Generar QR"
                onClick={showQrCode}>
                {loadingCreate ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={24} sx={styles.circularProgress} />
                    <span style={{ marginLeft: '8px' }}>Reclamar</span>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <QrCodeScannerIcon sx={styles.circularProgress} />
                    <span style={{ marginLeft: '8px' }}>Reclamar</span>
                  </Box>
                )}
              </Button>
            )}

            {(!isAuthenticated || (!disableBenefit && !detailsCouponsModal?.isClaimed)) && (
              <Button sx={{ ...styles.bottom }} disabled={loadingCreate} onClick={redeemBenefit}>
                {loadingCreate ? (
                  <CircularProgress size={24} sx={styles.circularProgress} />
                ) : (
                  'Reclamar'
                )}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
