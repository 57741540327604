export const dataMenus = [
  {
    id: 1,
    name: 'Comercios',
    route: '/comercios',
    subMenus: [
      {
        id: 200,
        name: 'Registro de comercios',
        route: '/comercios/registro',
      },
      {
        id: 201,
        name: 'Lista de comercios',
        route: '/comercios/lista',
      },
    ],
    userTypes: ['Administrador', 'Usuario'],
  },
  {
    id: 2,
    name: 'Membresía',
    route: '/membresias',
    subMenus: [
      { id: 300, name: 'Registro de membresía', route: '/membresias/registro' },
      { id: 301, name: 'Lista de membresía', route: '/membresias/lista' },
      {
        id: 502,
        name: 'Reportes',
        route: '/membresias/reportes/reportesMembresias',
      },
      {
        id: 503,
        name: 'Reportes membresías y sus beneficiarios',
        route: '/membresias/reportes/reportesMembresiasBeneficiarios',
      },
    ],
    userTypes: ['Administrador', 'Usuario'],
  },
  {
    id: 3,
    name: 'Beneficios',
    route: '/beneficios',
    subMenus: [
      {
        id: 41,
        name: 'Registro de beneficios',
        route: '/beneficios/registro',
      },
      {
        id: 42,
        name: 'Lista de beneficios',
        route: '/beneficios/lista',
      },
      {
        id: 43,
        name: 'Lista de beneficios redimidos',
        route: '/beneficios/benficiosRedimidos'
      },
      {
        id: 44,
        name: 'Sucursal',
        route: '/beneficios/sucursal',
      },
      {
        id: 45,
        name: 'Reportes',
        route: '/beneficios/reporte',
      },
      {
        id: 46,
        name: 'Validar Cupón',
        route: '/beneficios/validarCupon',
      },
      
    ],
    userTypes: ['Administrador', 'Comercio', 'Usuario'],
  },
  {
    id: 4,
    name: 'Beneficiarios',
    route: '/beneficiarios',
    subMenus: [
      { id: 500, name: 'Registro de beneficiarios', route: '/beneficiarios/registro' },
      { id: 501, name: 'Lista de beneficiarios', route: '/beneficiarios/lista' },
      { id: 502, name: 'Reportes', route: '/beneficiarios/reportes' },
    ],
    userTypes: ['Administrador', 'Membresia', 'Usuario'],
  },

  {
    id: 5,
    name: 'Vendedores',
    route: '/vendedores',
    subMenus: [
      { id: 600, name: 'Registro de vendedores', route: '/vendedores/registro' },
      { id: 601, name: 'Lista de vendedores', route: '/vendedores/lista' },
    ],
    userTypes: ['Administrador', 'Usuario'],
  },
  {
    id: 6,
    name: 'Admin de usuarios',
    route: '/usuarios',
    subMenus: [
      {
        id: 100,
        name: 'Registro de usuarios',
        route: '/usuarios/registro',
      },

      {
        id: 101,
        name: 'Lista de usuarios',
        route: '/usuarios/lista',
      },
    ],
    userTypes: ['Administrador'],
  },
];
