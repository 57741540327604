import { yupResolver } from '@hookform/resolvers/yup';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { useTheme } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { capitalizeTheFirstLetter, decryptData } from '../../../../common/utils/functions';
import { TDispatch, TodoSuccessResponse } from '../../../../common/utils/types.d';
import { Context } from '../../../../common/utils/userContext';
import { getFileUrlS3 } from '../../../../lib';
import { citiesListPage } from '../../../../redux/addresses/cities/actionCreator';
import { TCitiesReducer } from '../../../../redux/addresses/cities/types';
import { departmentsListPage } from '../../../../redux/addresses/departments/actionCreator';
import { TDepartmentsReducer } from '../../../../redux/addresses/departments/types';
import {
  beneficiariesByBenefitsPage,
  getBeneficiaryByIdListPage,
} from '../../../../redux/beneficiaries/actionCreator';
import { TBeneficiaryReducer } from '../../../../redux/beneficiaries/types';
import {
  clearCreateBenefitBeneficiaryPage,
  createBenefitBeneficiaryPage,
} from '../../../../redux/benefitBeneficiaries/actionCreator';
import { TCreateBenefitBeneficiaryReducer } from '../../../../redux/benefitBeneficiaries/types.d';
import { benefitCategoryListPage } from '../../../../redux/benefits/benefitCategory/actionCreator';
import {
  benefitsRandomListPage,
  benefitsWithPaginationListPage,
  clearBenefitsRandomListPage,
  clearBenefitsWithPaginationListPage,
} from '../../../../redux/benefits/benefits/actionCreator';
import { TBenefitsRandomReducer } from '../../../../redux/benefits/benefits/types.d';
import { RootState } from '../../../../redux/store';
import {
  clearUserLoggedUpdatePasswordListPage,
  userLoggedPasswordUpdatePage,
} from '../../../../redux/userPermissions/user/actionCreator';
import { TUpdateUserLoggedPasswordReducer } from '../../../../redux/userPermissions/user/types';
import { CouponsV } from '../../components/couponsPage/index';
import {
  TBenefitCategory,
  TBenefitsWithPaginationReducer,
  TLoginDispatch,
} from '../../components/couponsPage/types.d';
import { TCities, TDataBenefit, TDataDepartments, TDetailModal } from './types.d';
import schema from './yupSchema';
import schemaUpdatePassword from './yupSchemaUpdatePassword';

export const CouponApplicationC = () => {
  /**
   * navigate
   */
  const navigate = useNavigate();

  /*
    dispatch
    */
  const dispatch = useDispatch();

  /**
   * useParams
   */
  const [searchParams, setParams] = useSearchParams();
  const category = Number(searchParams?.get('category')) || 1;

  /**
   * Constante con array de objetos para asignar un nombre de periodicidad con su numero
   */
  const contentPeriodicity = useMemo(
    () => [
      { name: 'día', value: 1 },
      { name: 'semana', value: 7 },
      { name: 'quincena', value: 15 },
      { name: 'mes', value: 30 },
    ],
    []
  );

  /**
   * States
   */
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [stateDispatch, setStateDispatch] = useState<boolean>(false);
  const [nameCategory, setNameCategory] = useState<string | undefined>();
  const [benefitsDataMapped, setBenefitsDataMapped] = useState<TDataBenefit[]>([]);
  const [detailsCouponsModal, setDetailsCouponsModal] = useState<TDetailModal | undefined>();
  const [dataBenefitsRandom, setDataBenefitsRandom] = useState<TDataBenefit[] | undefined>([]);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataBenefitsCarruselRandom, setDataBenefitsCarruselRandom] = useState<
    TDataBenefit[] | undefined
  >([]);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const [userCredentials, setUserCredentials] = useState({ userName: '', userType: '' });
  const { handleSessionCouponsPage } = useContext(Context);
  // estado para validacion de errores
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  // estado para abrir el modal de contraseña
  const [openModalPassword, setOpenModalPassword] = useState(false);
  //estado para verificar si tiene la sesion iniciada
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  // estado para abrir el qr
  const [qrOpen, setQrOpen] = useState<boolean>(false);
  //estado para almacenar el qr
  const [qrCode, setQrCode] = useState<string>('');
  //estado para cambiar el boton de QR
  const [buttonMode, setButtonMode] = useState(false);
  const [disableBenefit, setDisableBenefit] = useState<boolean>(false);
  // TIPO DE SOLICITU
  const [nameRequest, setNameRequest] = useState<string>();
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [asd, setAsd] = useState<boolean>(false);

  // Estado para el campo de búsqueda
  const [inputState, setInputState] = useState<boolean>(false);

  // Estado para determinar si la pantalla es grande
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(window.innerWidth > 900);

  // Funciones para manejar el cambio de estado en el input
  const handleInputFocus = () => setInputState(true); // Maneja el foco del campo de búsqueda
  const handleInputBlur = () => setInputState(false); // Maneja la pérdida de foco del campo de búsqueda

  const loginDispatch: TLoginDispatch = useSelector(
    (dataState: RootState) => dataState?.loginBeneficiary
  );
  /**
   * Dispatch de benefitCategory
   */
  const benefitCategoryDispatch: TBenefitCategory = useSelector(
    (dataState: RootState) => dataState?.benefitCategories
  );

  const beneficiaryByBenefitCategoryDispatch: TBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaryByBenefits
  );

  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );

  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * Dispatch de beneficiario
   */
  const getBeneficiaryByIdDispatch: TBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiary
  );

  /**
   * Obtener todos los beneficios de manera aleatoria
   */
  const getAllBenefitsRandomDispatch: TBenefitsRandomReducer = useSelector(
    (dataState: RootState) => dataState?.benefitsRandom
  );

  /**
   * Obtener todos los beneficios con pagination
   */
  const getAllBenefitsDispatch: TBenefitsWithPaginationReducer = useSelector(
    (dataState: RootState) => dataState?.benefitsWithPagination
  );
  /**
   * Obtener todos los beneficios de manera aleatoria
   */
  const benefitBeneficiaryCreateDispatch: TCreateBenefitBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.benefitBeneficiariesCreated
  );

  /**
   * OBTENER BENEFICIOS
   */
  const validityBeneficiary: TBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.validityBeneficiary
  );

  /**
   * Dispatch de updatePassword
   */
  const userLoggedUpdatePasswordDispatch: TUpdateUserLoggedPasswordReducer = useSelector(
    (dataState: RootState) => dataState?.loggedPasswordUpdated
  );
  /**
   * Dispatch de Beneficio por categorías
   */
  const benefitCategoryData = benefitCategoryDispatch?.benefitCategories;

  /**
   * UseForm
   */
  const { control, watch, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (loginDispatch?.login?.identificationNumber) {
      dispatch(
        getBeneficiaryByIdListPage({
          identificationNumber: loginDispatch?.login?.identificationNumber,
        }) as TDispatch
      );
    } else {
      reset({});
    }
  }, [loginDispatch, dispatch, reset]);

  /**
   * useForm
   */
  const {
    handleSubmit: handleSubmitUpdatePassword,
    control: controlUpdatePassword,
    watch: watchUpdatePassword,
    reset: resetUpdatePassword,
  } = useForm({
    resolver: yupResolver(schemaUpdatePassword),
  });

  // extraccion del token desde el almacenamoiento local
  const token = localStorage.getItem('vs2-tk');
  /**
   * Función de validar contraseñas
   */
  const validatePassword = () => {
    setTimeout(() => {
      dispatch(
        userLoggedPasswordUpdatePage({
          password: watchUpdatePassword('password'),
          newPassword: watchUpdatePassword('confirmPass'),
          token: `${token}`,
        }) as TDispatch
      );
      setLoading(true);
    }, 500);
  };

  //funcion para validar todos los errores
  const validateAll = async (values: { newPassword: string }) => {
    try {
      await schemaUpdatePassword.validate(values, { abortEarly: false });
      return {}; // No hay errores
    } catch (error: any) {
      const errores: any = {};

      error.inner.forEach((e: { path: string | number; message: string }) => {
        if (!errores[e.path]) {
          errores[e.path] = [];
        }
        errores[e.path].push(e.message);
      });
      return errores; // Devuelve un objeto con todos los errores
    }
  };

  //funcion para cambiar la contraseña
  const newPassword = watchUpdatePassword('newPassword');
  useEffect(() => {
    validateAll({ newPassword: newPassword }).then((errores: { newPassword: string[] }) => {
      if (Object.keys(errores).length) {
        setValidationErrors(errores?.newPassword);
      }
    });
  }, [newPassword, watchUpdatePassword]);

  //funcion para enviar el cambio de contraseña y cerrar el modal
  const sendChangePassword = () => {
    setOpenModalPassword(!openModalPassword);
    setOpen(false);
    resetUpdatePassword({ password: '', newPassword: '', confirmPass: '' });
  };

  /*
   * Función que asiga la periodicidad con su nombre en el array para que se vea el nombre en la vista
   */
  const getPeriodicityByNumber = useCallback(
    (x: number | undefined): string => {
      const foundItem = contentPeriodicity.find((item) => item.value === x);
      return foundItem?.name ?? '';
    },
    [contentPeriodicity]
  );

  /*
   * Función para asignar imagen a la path por cada objeto.
   */
  const processBenefitsData = useCallback(
    (benefits: TDataBenefit[]) => {
      return benefits.map((x: any) => {
        const img: string = x?.path || '';
        const result: string = getFileUrlS3(`benefits/${img}`);
        const thirdParty =
          x?.thirdParty?.branchsOffices?.map((office: any) => ({
            city: `${capitalizeTheFirstLetter(
              office?.department?.name
            )} - ${capitalizeTheFirstLetter(office?.city?.name)}`,
            address: capitalizeTheFirstLetter(office?.description),
          })) || [];

        return {
          ...x,
          path: result || ImageNotSupportedIcon,
          trade: x?.thirdParty?.businessName,
          thirdParty,
          endDate: ` Vence el ${x?.benefitValidity?.endDate}`,
          usagePerson: ` ${x?.benefitValidity?.usagePerson} uso por ${getPeriodicityByNumber(
            x?.benefitValidity?.periodicity
          )}`,
          useLimit: `Total beneficios: ${
            x?.benefitValidity?.useLimit === 0 ? 'Ilimitado' : x?.benefitValidity?.useLimit
          }`,
        };
      });
    },
    [getPeriodicityByNumber]
  );
  const redeemedProcessBenefitsData = (benefits: TDataBenefit[]) => {
    return benefits.map((x: any) => {
      const img: string = x?.benefit?.path || '';
      const result: string = getFileUrlS3(`benefits/${img}`);
      const thirdParty =
        x?.benefit?.thirdParty?.branchsOffices?.map((office: any) => ({
          city: `${capitalizeTheFirstLetter(office?.department?.name)} - ${capitalizeTheFirstLetter(
            office?.city?.name
          )}`,
          address: capitalizeTheFirstLetter(office?.description),
        })) || [];

      return {
        ...x?.benefit,
        path: result || ImageNotSupportedIcon,
        trade: x?.benefit?.thirdParty?.businessName,
        thirdParty,
        endDate: ` Vence el ${x?.benefit?.benefitValidity?.endDate}`,
        usagePerson: ` ${x?.benefit?.benefitValidity?.usagePerson} uso por ${getPeriodicityByNumber(
          x?.benefit?.benefitValidity?.periodicity
        )}`,
        // useLimit: `Total beneficios: ${x?.benefit?.benefitValidity?.useLimit}`,
        useLimit: `Total beneficios: ${
          x?.benefit?.benefitValidity?.useLimit === 0
            ? 'Ilimitado'
            : x?.benefit?.benefitValidity?.useLimit
        }`,
      };
    });
  };

  useEffect(() => {
    const beneficiary = beneficiaryByBenefitCategoryDispatch.beneficiary;
    const qrImage = getFileUrlS3(`users/${beneficiary?.idBeneficiary}/qr/${beneficiary?.qrCode}`);
    setQrCode(qrImage);
  }, [beneficiaryByBenefitCategoryDispatch]);

  /*
   * Función para buscar por idBenefit en dataRandom.
   */
  const getItemByNumber = (x: number | undefined) => {
    return dataBenefitsRandom?.find((item) => item?.idBenefit === x);
  };

  /*
   * Función para buscar por idBenefit en dataBenefits.
   */
  const getItemBenefitByNumber = (x: number | undefined) => {
    return benefitsDataMapped?.find((item) => item?.idBenefit === x);
  };

  /*
   * Función para abrir y cerrar modal.
   */
  const sendConfirm = () => {
    setOpenModal(!openModal);
  };

  /**
   * Watch del departamento seleccionado
   */
  const watchDepartment = watch('address.idDepartment');

  const selectedCityId = watch('address.idCity');
  const handleClearSelections = () => {
    setValue('address.idDepartment', 0);
    setValue('address.idCity', 0);
    window.location.reload();
  };

  useEffect(() => {
    // Filtrar beneficios por ciudad seleccionada
    if (selectedCityId) {
      const filteredBenefits =
        getAllBenefitsDispatch?.getBenefitsWithPagination?.filter((benefit) =>
          benefit?.benefitCity?.some((benefitCity) => benefitCity?.idCity === selectedCityId)
        ) || [];
      const processedData = processBenefitsData(filteredBenefits || []);
      setBenefitsDataMapped(processedData);
    }
  }, [selectedCityId, getAllBenefitsDispatch, processBenefitsData]);
  /**
   * Función que captura el idBenefit y devuelve objeto a su respectivo modal detalle.
   */
  const handleImage = (x: number | undefined) => {
    if (x !== null) {
      setDetailsCouponsModal(getItemBenefitByNumber(x) || getItemByNumber(x));
    }
  };

  /**
   * Función que busca beneficios
   */
  const HandleSearchAllItem = () => {
    // const requestSearch = watch('searchBenefits')?.toString();
    // if (requestSearch) {
    //   navigate(`search?q=${requestSearch}`);
    // }
    dispatch(
      benefitsWithPaginationListPage({
        page: 1,
        pageSize: 16,
        search: watch('searchBenefits'),
      }) as TDispatch
    );
    setLoading(true);
  };

  /*
   * Función para ver más beneficios.
   */
  const goForward = () => {
    navigate('search?');
  };

  /*
   * Función para ver información personal.
   */
  const goToPersonalInfo = () => {
    navigate('/infoPersonal');
  };

  /*
   * Función para ver información personal.
   */
  const goToCouponsDashboard = () => {
    navigate('/cuponesDashboard');
  };

  /*
   * Función para ir a vista login.
   */
  const goToLoginBeneficiary = () => {
    navigate('/loginBeneficiario');
  };

  /**
   * Función que filtra por categoría idCategory
   */
  const handleCategory = (x: number) => {
    setAsd(false);
    setDisableBenefit(false);
    if (x !== 1) {
      searchParams?.delete('q');
      searchParams?.set('page', '1');
      navigate(`search?category=${x}`);
      searchParams?.set('category', `${x}`);
      setParams(searchParams, { replace: true });
      dispatch(
        benefitsWithPaginationListPage({
          page: 1,
          pageSize: 8,
          idCategory: x,
        }) as TDispatch
      );
      setLoading(true);
    } else {
      searchParams?.delete('category');
      dispatch(
        benefitsWithPaginationListPage({
          page: 1,
          pageSize: 16,
        }) as TDispatch
      );
      const processedData = processBenefitsData(
        getAllBenefitsDispatch?.getBenefitsWithPagination || []
      );
      setBenefitsDataMapped(processedData);
    }
  };

  //funion para manejar el evento cuando el cursor entra en un elemento
  const handleMouseEnter = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  //funcion para cuando el cursor sale de un elemento
  const handleMouseLeave = () => {
    setOpen(false);
  };

  // funcion para generar un color en una cadena de texto
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  // funcion para generar un avatar basado en un nombre proporcionado
  const stringAvatar = (name: string, location?: string) => {
    const avatarName = name || 'Usuario Bonus plus';
    return {
      sx: {
        bgcolor: location === 'header' ? theme.palette.primary.main : stringToColor(name),
        color: location === 'header' ? theme.palette.common.white : '#fff',
        fontSize: location === 'header' ? '0.95rem' : '1.25',
        width: location === 'header' ? '35px' : '45px',
        height: location === 'header' ? '35px' : '45px',
        marginTop: location === 'header' && '0',
        fontWeight: 'bold',
      },
      children: `${avatarName.split(' ')[0][0].toUpperCase()}${
        avatarName.split(' ')[1] ? avatarName.split(' ')[1][0].toUpperCase() : ''
      }`,
    };
  };

  // función solicitud de cupón
  const redeemBenefit = () => {
    if (isAuthenticated) {
      dispatch(
        createBenefitBeneficiaryPage({
          idBenefit: detailsCouponsModal?.idBenefit,
          email: getBeneficiaryByIdDispatch?.beneficiary?.email,
        }) as TDispatch
      );
      setStateDispatch(true);
    } else {
      navigate('/loginBeneficiario');
    }
  };

  //funcion para quitar el qr en caso de cerrar el modal
  const closeQrCode = () => {
    if (!asd) {
      setQrOpen(false);
      setButtonMode(false);
    }
    setButtonMode(false);
  };

  // funcion para mostrar el codigo qr
  const showQrCode = () => {
    setLoadingButton(true);
    setQrOpen(!qrOpen);
  };

  // MAPEANDO DATA DE BENEFICIOS RECLAMADOS
  useEffect(() => {
    if (!beneficiaryByBenefitCategoryDispatch.loading) {
      const data = (
        validityBeneficiary?.beneficiary?.idBeneficiary
          ? beneficiaryByBenefitCategoryDispatch?.beneficiary?.benefitBeneficiaries
          : []
      )?.map((x: any) => {
        return {
          ...x,
        };
      });
      const processedData = redeemedProcessBenefitsData(data || []);

      if (
        processedData?.length < 1 &&
        nameRequest &&
        !validityBeneficiary?.beneficiary?.idBeneficiary
      ) {
        setNameRequest(nameRequest);
        // toast(`No se encontraron beneficios ${nameRequest}`, {
        //   type: 'info',
        //   autoClose: 3000,
        //   hideProgressBar: true,
        // });
      }
      setBenefitsDataMapped(processedData);
      setNameRequest(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validityBeneficiary?.beneficiary?.idBeneficiary, setBenefitsDataMapped, nameRequest]);

  // FUNCIÓN PARA OBTENER BENEFICIOS
  const handleBenefitCategory = (type: string) => {
    dispatch(beneficiariesByBenefitsPage(loginDispatch?.login?.identificationNumber) as TDispatch);
    setAsd(true);
    setButtonMode(true);
    navigate('/');
    setNameRequest(type);
    setDisableBenefit(true);
  };

  /**
   * UseEffect pendiente a nueva contraseña con limpieza de campos
   */
  useEffect(() => {
    if (loading)
      if (
        userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword &&
        !userLoggedUpdatePasswordDispatch?.error
      ) {
        resetUpdatePassword({
          password: '',
          newPassword: '',
          confirmPass: '',
        });
        dispatch(clearUserLoggedUpdatePasswordListPage() as TDispatch);
        setOpenModalPassword(false);
        toast.success('Contraseña actualizada exitosamente', {
          hideProgressBar: true,
        });
      } else if (
        !userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword &&
        userLoggedUpdatePasswordDispatch?.error
      ) {
        const message = userLoggedUpdatePasswordDispatch?.error?.response
          ? (userLoggedUpdatePasswordDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearUserLoggedUpdatePasswordListPage() as TDispatch);
      }
  }, [
    userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword,
    userLoggedUpdatePasswordDispatch?.error,
    dispatch,
    loading,
    resetUpdatePassword,
  ]);

  useEffect(() => {
    if (
      getAllBenefitsDispatch?.getBenefitsWithPagination?.length &&
      !getAllBenefitsDispatch?.loading
    ) {
      const processedData = processBenefitsData(
        getAllBenefitsDispatch?.getBenefitsWithPagination || []
      );
      // Estado donde se almacenan los beneficios reclamados
      const claimedBenefitsData = validityBeneficiary?.beneficiary?.benefitBeneficiaries || [];

      // Agregar atributo 'isClaimed' a cada beneficio
      const updatedData = processedData.map((benefit) => ({
        ...benefit,
        isClaimed: claimedBenefitsData.some((claimed) => claimed.idBenefit === benefit.idBenefit),
      }));


      setQrOpen(false);
      setBenefitsDataMapped(updatedData);
      setLoading(false);
    } else if (
      !getAllBenefitsDispatch?.getBenefitsWithPagination?.length &&
      !getAllBenefitsDispatch?.loading
    ) {
      setQrOpen(false);
      if (category && !getAllBenefitsDispatch?.getBenefitsWithPagination?.length) {
        toast('No se han encontrado resultados en esta categoría', {
          type: 'info',
          autoClose: 3000,
          hideProgressBar: true,
        });

        setBenefitsDataMapped([]);
        setLoading(false);
      }

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getAllBenefitsDispatch?.getBenefitsWithPagination,
    getAllBenefitsDispatch?.loading,
    category,
  ]);

  /**
   * UseEffect los 10 beneficios random
   */
  useEffect(() => {
    if (
      !getAllBenefitsRandomDispatch?.benefitsRandom &&
      !getAllBenefitsRandomDispatch?.error &&
      !getAllBenefitsRandomDispatch?.loading
    )
      dispatch(benefitsRandomListPage({ limit: 10 }) as TDispatch);
  }, [
    getAllBenefitsRandomDispatch?.benefitsRandom,
    getAllBenefitsRandomDispatch?.error,
    getAllBenefitsRandomDispatch?.loading,
    dispatch,
  ]);

  // use effect pendiente de adquirir un beneficio
  useEffect(() => {
    if (stateDispatch)
      if (
        benefitBeneficiaryCreateDispatch?.benefitBeneficiaryCreated &&
        !benefitBeneficiaryCreateDispatch?.error
      ) {
        toast.success('Solicitud exitosamente', {
          autoClose: 3000,
          hideProgressBar: true,
        });
        dispatch(clearCreateBenefitBeneficiaryPage() as TDispatch);
        reset({ email: '' });
        setQrOpen(true);
        setStateDispatch(false);
        // setOpenModal(!openModal);
      } else if (
        !benefitBeneficiaryCreateDispatch?.benefitBeneficiaryCreated &&
        benefitBeneficiaryCreateDispatch?.error
      ) {
        const message = benefitBeneficiaryCreateDispatch?.error?.response
          ? (benefitBeneficiaryCreateDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        dispatch(clearCreateBenefitBeneficiaryPage() as TDispatch);
        reset({ email: '' });
        setStateDispatch(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    benefitBeneficiaryCreateDispatch?.benefitBeneficiaryCreated,
    benefitBeneficiaryCreateDispatch?.error,
    stateDispatch,
    dispatch,
    openModal,
    reset,
  ]);

  /**
   * UseEffect los 10 beneficios random
   */
  useEffect(() => {
    if (getAllBenefitsRandomDispatch?.benefitsRandom && !getAllBenefitsRandomDispatch?.loading) {
      const processedData = processBenefitsData(getAllBenefitsRandomDispatch?.benefitsRandom || []);
      if (getAllBenefitsRandomDispatch?.benefitsRandom?.length >= 1) {
        setDataBenefitsCarruselRandom(processedData?.slice(0, 10));
        setDataBenefitsRandom(processedData?.slice(0, 4));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllBenefitsRandomDispatch?.benefitsRandom, getAllBenefitsRandomDispatch?.loading]);

  /**
   * UseEffect pendiente al select
   */
  useEffect(() => {
    if (category) {
      const seeCategory = benefitCategoryData?.find((item) => item?.idCategory === category)?.name;
      setNameCategory(seeCategory);
    }
  }, [category, loading, benefitCategoryData]);

  /**
   * UseEffect pendiente al presionar input Búsqueda
   */
  useEffect(() => {
    dispatch(beneficiariesByBenefitsPage(loginDispatch?.login?.identificationNumber) as TDispatch);
    schemaUpdatePassword
      .validate({ password: '', newPassword: '' }, { abortEarly: false })
      .catch(() => {
        validateAll({ newPassword: '' }).then((errores: { newPassword: string[] }) => {
          if (Object.keys(errores).length) {
            setValidationErrors(errores?.newPassword);
          }
        });
      });
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter' && watch('searchBenefits')) {
        event.preventDefault();
        HandleSearchAllItem();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * UseEffect que obtiene data encriptada en localStorage
   */
  useEffect(() => {
    if (!window.localStorage.getItem('vs2-unx') && !window.localStorage.getItem('vs2-tux')) {
      setUserCredentials({
        userName: '',
        userType: '',
      });
    } else {
      setUserCredentials({
        userName: decryptData(window.localStorage.getItem('vs2-unx') || '', '3573n035uN70K3n'),
        userType: decryptData(
          window.localStorage.getItem('vs2-tux') || '',
          '3573n035uN70K3n'
        )?.split(';')[0],
      });
    }
  }, []);

  // Efecto para manejar el cambio en el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);
  /**
   * UseEffect para llamar a Cities
   */

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(benefitCategoryListPage() as TDispatch);
    dispatch(clearBenefitsRandomListPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(clearCreateBenefitBeneficiaryPage() as TDispatch);
    dispatch(clearBenefitsWithPaginationListPage() as TDispatch);
    dispatch(clearUserLoggedUpdatePasswordListPage() as TDispatch);
    dispatch(citiesListPage() as TDispatch);
    dispatch(
      benefitsWithPaginationListPage({
        page: 1,
        pageSize: 16,
      }) as TDispatch
    );
  }, [dispatch]);

  // UseEffect para verificar el token y eliminar el formulario del modal si esta autenticado

  useEffect(() => {
    setParams();
    const token = localStorage.getItem('vs2-tk');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Definición del estado para manejar el ancla del menú
  const [anchorElCategories, setAnchorElCategories] = React.useState<HTMLElement | null>(null);
  // Determina si el menú de categorías está abierto o cerrado
  const openCategories = Boolean(anchorElCategories);

  // Función que se ejecuta cuando se hace clic en el botón o elemento que debe mostrar el menú
  const handleClickCategories = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCategories(event.currentTarget);
  };

  // Función que se ejecuta para cerrar el menú, estableciendo el ancla en null
  const handleCloseCategories = () => {
    setAnchorElCategories(null);
  };

  return (
    <CouponsV
      schema={schema}
      handleSubmit={handleSubmit}
      watchDepartment={watchDepartment}
      dataDepartments={dataDepartments}
      dataCities={dataCities}
      control={control}
      openModal={openModal}
      inputState={inputState}
      isLargeScreen={isLargeScreen}
      handleInputFocus={handleInputFocus}
      handleInputBlur={handleInputBlur}
      goForward={goForward}
      sendConfirm={sendConfirm}
      handleImage={handleImage}
      open={open}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      stringAvatar={stringAvatar}
      anchorEl={anchorEl}
      userCredentials={userCredentials}
      goToPersonalInfo={goToPersonalInfo}
      nameCategory={nameCategory}
      redeemBenefit={redeemBenefit}
      handleCategory={handleCategory}
      limit={benefitsDataMapped.length > 16}
      benefitsDataMapped={benefitsDataMapped}
      dataBenefitsRandom={dataBenefitsRandom}
      detailsCouponsModal={detailsCouponsModal}
      handleClearSelections={handleClearSelections}
      loading={getAllBenefitsDispatch?.loading}
      goToLoginBeneficiary={goToLoginBeneficiary}
      goToCouponsDashboard={goToCouponsDashboard}
      HandleSearchItemByCategory={HandleSearchAllItem}
      handleSessionCouponsPage={handleSessionCouponsPage}
      dataBenefitsCarruselRandom={dataBenefitsCarruselRandom}
      loadingCreate={benefitBeneficiaryCreateDispatch?.loading}
      benefitCategoryData={benefitCategoryData?.map((x) => {
        if (x?.idCategory === category) return { ...x, active: true };
        return { ...x, active: false };
      })}
      // props para actualizar la contraseña
      schemaUpdatePassword={schemaUpdatePassword}
      controlUpdatePassword={controlUpdatePassword}
      handleSubmitUpdatePassword={handleSubmitUpdatePassword}
      updateLoading={userLoggedUpdatePasswordDispatch?.loading}
      validatePassword={validatePassword}
      handleUpdatePassword={() => {}}
      validationErrors={validationErrors}
      sendChangePassword={sendChangePassword}
      openModalPassword={openModalPassword}
      handleBenefitCategory={handleBenefitCategory}
      //prop para verificar si el usuario inicio sesion
      isAuthenticated={isAuthenticated}
      // prop para pasar la funcion de mostrar qr
      qrOpen={qrOpen}
      qrCode={qrCode}
      closeQrCode={closeQrCode}
      buttonMode={buttonMode}
      // prop para verificar los beneficios solicitados
      disableBenefit={disableBenefit}
      loadingButton={loadingButton}
      showQrCode={showQrCode}
      handleCloseCategories={handleCloseCategories}
      openCategories={openCategories}
      handleClickCategories={handleClickCategories}
      anchorElCategories={anchorElCategories}
    />
  );
};
